<template>
  <div class="game-landing-page-header">
  </div>
  <div class="section">
    <!-- Main content (70% width) -->
    <div class="center-content">
      <!-- Show loader while fetching game data -->
      <div v-if="loading" class="loader">Loading...</div>

      <!-- Show game details once data is fetched -->
      <div v-else>
        <GameApplicationProposalDetails />
      </div>
    </div>

    <!-- Right-hand sidebar (30% width) - only show once page is loaded -->
    <div v-if="!loading" class="right-sidebar">
      <div class="box">
        <div class="box-header-img"></div>
        <div class="like-box">
          <div v-if="false">
            <div class="title">Love this game? Give it a 'like'!</div>
            <div class="sub-title">
              <div style="display: flex; flex-direction: row; align-items: center; margin-top: 10px;">
                <div class="like-count" style="padding-right: 10px;">{{ likeCount }}</div>
                <div class="button-heart">
                  <img src="@/assets/images/icons/default-heart-outlined.svg" alt="Heart Icon" @click="handleLike"
                    v-if="isLiked" />
                  <img src="@/assets/images/icons/active-heart-outlined.svg" alt="Heart Icon" @click="handleLike"
                    v-else />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; margin-bottom: 20px;">
              <div class="chip">
                {{ this.gameDetails.daysUntilEnd }} days left
              </div>
              <div>
                <div style="display: flex; flex-direction: row;">
                  <div class="like-count" style="padding-right: 10px;">{{ likeCount }}</div>
                  <div class="button-heart">
                    <img src="@/assets/images/icons/default-heart-outlined.svg" alt="Heart Icon" @click="handleLike"
                      v-if="isLiked" />
                    <img src="@/assets/images/icons/active-heart-outlined.svg" alt="Heart Icon" @click="handleLike"
                      v-else />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div style="display: flex; justify-content: flex-start;">
                <div>
                  <div class="card-title">{{ this.chartData[0].value }} USDC</div>
                  <div class="card-sub-title">{{ this.chartData[0].name }}</div>
                </div>
              </div>
              <div>
                <GamePieChart :chartData="chartData" />
              </div>
              <div style="display: flex; justify-content: space-between;">
                <!-- <div style="display: flex; justify-content: flex-end;"> -->
                <div :style="{ display:'flex', flexDirection: 'column', alignItems: 'flex-end' ,visibility: isAccount ? 'show':'hidden'}">
                  <div class="card-bottom-title">{{ this.userContribution.contribution }} USDC</div>
                  <div class="card-sub-title">Your contribution</div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: flex-end;">
                  <div class="card-bottom-title">{{ this.chartData[1].value }} USDC</div>
                  <div class="card-sub-title">{{ this.chartData[1].name }}</div>
                </div>
              </div>
            </div>
            <div>
              <div class="contribution-title">
                Contribute to {{ selectedGame.title }}
              </div>

              <div class="amount-container">
                <label for="amount">Amount of USDC</label>
                <input v-model.number="amount" type="number" id="amount" placeholder="Enter amount" class="amount-input"
                  min="0" @input="validateInput" required />
                <label style="margin-top: 5px; font-size: 14px; font-weight: 500;" v-if="isAccount">
                  Your balance: {{ usdcBalance }} USDC
                </label>
              </div>

              <div>
                <button @click="handleContribution" class="contribute-button" :disabled="!isValidAmount"
                  :class="{ 'disabled-button': !isValidAmount }">
                  Contribute
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- make this hide in mobile view start -->

      <div class="box">
        <div class="like-box">
          <div class="toggle-button" @click="toggleMilestone">
            <button :aria-expanded="isMilestoneOpen">
              {{ isMilestoneOpen ? 'Milestones ' : 'Milestones' }}
            </button>
            <img src="@/assets/images/icons/arrow-button-light.svg" alt="Heart Icon"
              :class="{ 'arrow-up': isMilestoneOpen }" v-if="this.isMobile" />
          </div>
          <div class="milestones-section" v-for="(milestone, index) in this.selectedGame.milestones" :key="milestone.id"
            v-if="isMilestoneOpen || !this.isMobile">
            <div class="milestones-circle" :class="{ 'circle-active': false }">{{ index + 1 }}</div>
            <div class="content">
              <h3 class="milestones-title">{{ milestone.title }}</h3>

              <div class="milestones-html quillWrapper">
                <div class="quill-container">
                  <div class="ql-editor ql-blank">
                    <div v-html="milestone.description"></div>
                  </div>
                </div>
              </div>
              <div class="milestones-transaction-hash" v-if="false">
                Transaction details
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- make this hide in mobile view end -->

    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import GameApplicationProposalDetails from './gameApplicationProposalDetails.vue';
import { getGameById, gameRating, gameContributed } from "@/api/agfAPI";
import GamePieChart from './gameProposalComponents/gamePieChart.vue';
import { useBlockChainStore } from '@/store/blockchain'
import { formatEther, parseGwei, parseEther } from 'viem';

export default {
  name: 'Game',
  setup() {
    const route = useRoute();
    const router = useRouter();
  },
  data() {
    return {
      gameId: 10,
      loading: true,
      likeCount: 0, // Like count state
      isLiked: false, // Is the game liked
      amount: null,
      isMilestoneOpen: false,
      isMobile: window.innerWidth <= 900,
      usdcBalance: 0,
      userContribution: {
        contribution: '0'
      },
      isValidAmount: 0,
      gameDetails: {

      },
      chartData: [
        {
          "name": "Total contribution",
          "value": 0,
        },
        {
          "name": "Goal",
          "value": 0,
        },

      ]
    };
  },
  components: {
    GameApplicationProposalDetails,
    GamePieChart
  },
  computed: {
    isAccount() {
      const blockChainStore = useBlockChainStore();
      console.log("blockChainStore ", blockChainStore.isLoggedIn())
      return blockChainStore.isLoggedIn();
    }
  },
  created() {
    this.fetchGameDataById();
  },
  mounted() {
    this.checkLikeStatus(); // Check if the game is liked when the component is mounted
    this.getGameDetails(2);
    this.getUserContribution(2);
    if (this.isAccount) {
      this.getBalance('USDC');
    }
  },
  methods: {
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    toggleMilestone() {
      this.isMilestoneOpen = !this.isMilestoneOpen;
    },
    validateInput() {
      // Get the value from the input field
      const value = event.target.value;
      // If the value is negative, set it to zero
      this.isValidAmount = this.amount > 0 && this.amount <= this.usdcBalance;
    },
    // parse to eth
    convertToEth(value) {

      let gwei = value.toString();

      let x = formatEther(gwei).toString();
      return Number.parseFloat(x).toFixed(2);

    },
    async getGameDetails(gameId) {
      // await this.delay(2000); // Wait for 2 seconds

      console.log("getGameDetails start ===????.>>>")
      const blockChainStore = useBlockChainStore();

      let contract = await blockChainStore.getContract('AGFManager');
      console.log("getGameDetails contrat end  ===????.>>>", contract)
      const gameProp = await contract.methods.getGameRecordDetails(gameId).call();
      const gameRecord = {
        gameId: gameId,
        contributionNeeded: this.convertToEth(gameProp.contributionNeeded),
        contributionReleased: this.convertToEth(gameProp.contributionReleased),
        totalContribution: this.convertToEth(gameProp.totalContribution),
        isActive: gameProp.isActive,
        startTime: new Date(Number(gameProp.startTime) * 1000), // Ensure it's a number and convert to date
        endTime: new Date(Number(gameProp.endTime) * 1000), // Ensure it's a number and convert to date
        daysUntilEnd: Math.ceil((new Date(Number(gameProp.endTime) * 1000) - new Date()) / (1000 * 60 * 60 * 24)), // Calculate days remaining
      };
      this.gameDetails = gameRecord;
      this.chartData = [
        {
          "name": "Total contribution",
          "value": gameRecord.totalContribution ?? 0,
        },
        {
          "name": "Goal",
          "value": gameRecord.contributionNeeded ?? 0,
        },
        {
          "name": "Goal",
          "value": gameRecord.contributionNeeded,
        },
      ];
    },

    async getUserContribution(gameId) {
      console.log("getUserContribution");
      await this.delay(1000); // Wait for 1 second

      if (this.isAccount) {
        const blockChainStore = useBlockChainStore();

        let contract = await blockChainStore.getContract('AGFManager');

        // Call the smart contract to get user contributions
        const userContribution = await contract.methods.getUserContributionRecords(blockChainStore.getAccount).call();

        // Check if userContribution is valid before accessing its properties
        if (!userContribution || !userContribution.gameContribution || !userContribution.gameIds || !userContribution.gameWithdrawalRecords || !userContribution.totalUserContribution) {
          console.log("Invalid user contribution data");
          return;
        }

        console.log(userContribution);

        // Convert the response into a more manageable structure
        const contributionRecord = {
          gameContribution: userContribution.gameContribution.map(contribution => this.convertToEth(contribution)), // Convert to Ether
          gameIds: userContribution.gameIds.map(id => Number(id)), // Convert to regular numbers
          gameWithdrawalRecords: userContribution.gameWithdrawalRecords.map(record => this.convertToEth(record)), // Convert to Ether
          totalUserContribution: this.convertToEth(userContribution.totalUserContribution), // Convert to Ether
        };

        // Build the structured array
        const contributionsArray = contributionRecord.gameIds.map((gameId, index) => ({
          gameId: gameId, // gameId from gameIds
          withdrawal: contributionRecord.gameWithdrawalRecords[index], // withdrawal from gameWithdrawalRecords
          contribution: contributionRecord.gameContribution[index], // contribution from gameContribution
        }));

        // Structure the final object
        const result = {
          userTotalContribution: contributionRecord.totalUserContribution,
          contribution: contributionsArray,
        };

        // Ensure getContributionByGameId is called safely
        this.userContribution = this.getContributionByGameId(result, gameId);
        return this.userContribution; // Return the structured result if needed
      } else {
        console.log("Please log in...");
      }
    },
    // New method to get contribution by gameId
    getContributionByGameId(contributions, gameId) {
      const contributionDetails = contributions.contribution.find(item => item.gameId === gameId);

      if (contributionDetails) {
        return {
          gameId: contributionDetails.gameId,
          withdrawal: contributionDetails.withdrawal,
          contribution: contributionDetails.contribution,
        };
      } else {
        return null; // Return null if no contribution found for the given gameId
      }
    },
    async getBalance(tokenName) {
      const blockChainStore = useBlockChainStore();
      // await this.delay(3000); // Wait for 2 seconds

      let contract = await blockChainStore.getTokenContractByName(tokenName);
      const bal = await contract.methods.balanceOf(blockChainStore.getAccount).call();
      this.usdcBalance = this.convertToEth(bal);
    },
    async approveToken(tokenName, approveAddress, amount) {
      const blockChainStore = useBlockChainStore();

      let contract = await blockChainStore.getTokenContractByName(tokenName);

      return await contract.methods
        .approve(approveAddress, amount)
        .send({
          from: blockChainStore.getAccount,
        })
        .then(() => {
          // resolve();
          return true;
        })
        .catch((e) => {
          return false;
        });

    },
    async handleContribution() {
      console.log(`Contributing ${this.amount} USDC to ${this.selectedGame.title}`);
      if (this.isValidAmount) {

        const etherString = this.amount.toString(); // Convert to string

        let _gameId = this.gameDetails.gameId;
        let _amount = parseEther(etherString);
        const blockChainStore = useBlockChainStore();




        let agfFactory = await blockChainStore.getContract('AGFManager');

        const tokenName = 'USDC'; // Replace with your token address
        const approveAddress = "0xF3aEf1a6919E47783d8AfeE1011AEE4F08D7F768"; // Assuming AGFManager contract address is the one to approve
        const isApproved = await this.approveToken(tokenName, approveAddress, _amount); // Call approveToken first

        if (!isApproved) {
          console.log('Approval failed');
          return; // Exit if approval fails
        }
        let resBlock = await agfFactory
          .methods
          .contributeInGame(_gameId, _amount)
          .send(
            {
              from: blockChainStore.getAccount,
            },
            async (err, hash) => {

            }
          ).then((res) => {
            console.log(res.transactionHash);
            console.log("res ===>>>>");
            return {
              isError: false,
              hash: res.transactionHash,
            };
          })
          .catch((e) => {
            return {
              isError: true,
              hash: null,
            };
          });
        console.log("resBlock ", resBlock);

        if (!resBlock.isError) {
          // this.$toast.success('Contribution successful!');
          let data = {
            "gameId": _gameId,
            "amount": _amount,
            "txHash": resBlock.hash
          }
          let res = await gameContributed(data);
          console.log(res);
          this.getGameDetails(_gameId);
          this.getUserContribution(_gameId);
        }
      }
    },
    async fetchGameDataById() {
      const gameId = this.gameId; // Example game ID
      const gameKey = `game_${gameId}`;

      // Check if game data is in localStorage
      const cachedGameData = localStorage.getItem(gameKey);

      if (cachedGameData) {
        // Load from localStorage
        this.selectedGame = JSON.parse(cachedGameData);
        this.likeCount = this.selectedGame.likes;

        localStorage.setItem('selectedGameProposal', JSON.stringify(this.selectedGame));

        this.loading = false;
      } else {
        // Fetch from API if not in localStorage
        try {
          const response = await getGameById(gameId);
          if (response.isSuccess) {
            localStorage.setItem(gameKey, JSON.stringify(response.data));
            this.selectedGame = response.data;
            this.likeCount = this.selectedGame.likes;
            localStorage.setItem('selectedGameProposal', JSON.stringify(this.selectedGame));
          }
        } catch (error) {
          console.error('Error fetching game data:', error);
        } finally {
          this.loading = false;
        }
      }
    },
    async handleLike() {
      const gameId = this.gameId; // Example game ID
      const likeKey = `liked_game_${gameId}`;
      let data = {
        gameId: gameId,
        isLiked: !this.isLiked,
      }

      if (localStorage.getItem("token")) {
        if (this.isLiked) {
          this.likeCount += 1;
        } else {
          this.likeCount -= 1;
        }
        this.isLiked = !this.isLiked;
        await gameRating(data);

        localStorage.setItem(likeKey, 'true');
        localStorage.setItem(`likeCount_${gameId}`, this.likeCount);

      } else if (!localStorage.getItem(likeKey)) {
        this.likeCount += 1;
        this.isLiked = true;
        await gameRating(data);

        // Store the like status and like count in localStorage
        localStorage.setItem(likeKey, 'true');
        localStorage.setItem(`likeCount_${gameId}`, this.likeCount);
      } else {
        console.log('You have already liked this game.');
      }
    },
    checkLikeStatus() {
      const gameId = this.gameId; // Example game ID
      const likeKey = `liked_game_${gameId}`;
      const likeCountKey = `likeCount_${gameId}`;

      // Check localStorage for existing like status
      if (localStorage.getItem(likeKey)) {
        this.isLiked = true;
        this.likeCount = parseInt(localStorage.getItem(likeCountKey)) || 0;
      }
    }
  }
}
</script>

<style >
.section {
  display: flex;
  justify-content: space-between;
  margin: 80px auto 150px;
  padding: 50px;

  height: 100%;
  /* Make it full height */
}


.card-title {
  color: var(--main-text, #1C1C1C);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
}

.card-bottom-title {
  color: var(--main-text, #1C1C1C);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
}

.card-sub-title {
  color: var(--grey-text, #858585);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.like-count {
  color: #1C1C1C;
}

.contribution-title {
  color: var(--main-text, #1C1C1C);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.box {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;

  .like-box {
    border-radius: 15px;
    border: 1px solid var(--dark-grey-stroke, #999F9F);
    background: #FFF;
    gap: 40px;
    width: 400px;
    padding: 20px;

  }

  .title {
    color: var(--main-text, #1C1C1C);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .sub-title {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    color: var(--main-text, #1C1C1C);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;



    .button-heart {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 40px;
      height: 40px;
    }

    .button-heart.liked {
      filter: brightness(0.5) sepia(100%);
      fill: red;
    }
  }

  .box-header-img {
    display: flex;
    width: 237px;
    height: 256px;
    padding: 0.076px 0.137px 0.254px 0.389px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    background-image: url('@/assets/images/game/gamer_character.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}


.center-content {
  flex: 8;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

}

.milestones-section {
  display: flex;
  padding-top: 20px;

  .milestones-title {
    color: var(--main-text, #1C1C1C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .milestones-html {
    color: var(--grey-text, #858585);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px;

    .ql-editor ol,
    .ql-editor ul {
      padding-left: 0px;
    }
  }
}



.right-sidebar {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  margin-left: 20px;
  align-content: flex-start
}

.like-button {
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.like-button:hover {
  background-color: #45a049;
}

.loader {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.milestones-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 10px;
  float: left;
  text-align: center;



  color: var(--main-text, #1C1C1C);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 900px) {


  .section {
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .right-sidebar {
    order: -1;
    /* Move right-sidebar above main-content */
    width: 100%;
  }



  .game-landing-page-header {
    height: 300px;
    /* Adjust height for smaller screens */
    background-size: contain;
    /* Keep the entire image visible on smaller screens */
  }
}

.game-landing-page-header {
  width: 100%;
  height: 400px;
  background-image: url('@/assets/images/game/game_header.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* Ensures the image covers the container */
  margin-top: 100px;
}

.chip {
  padding: 10px 15px;
  background-color: #eeee;
  border-radius: 30px;
  font-size: 18px;
}

@media (max-width: 550px) {
  .box-header-img {
    display: none !important;
  }


}

@media (max-width: 500px) {

  .box {
    margin-bottom: 16px;
  }

  .amount-container {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .contribution-title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .card-title {
    font-size: 20px;
  }

  .card-bottom-title {
    font-size: 16px;
  }

  .chip {
    font-size: 14px;
    padding: 6px 15px;
  }

  .circle {
    font-size: 16px;
  }

  .box {
    width: 100%;

    .like-box {
      width: 100%;
      padding: 16px;
    }

    .title {
      font-size: 18px;
      text-align: center;
    }

    .sub-title {
      font-size: 18px;
    }
  }

  .game-landing-page-header {
    display: none;
  }

  .section {
    padding: 0px;
  }

  .right-sidebar {
    margin-left: 0px;
  }

  .box-header-img {
    display: none !important;
  }

  .toggle-button {
    font-size: 18px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;


    .arrow-up {
      transform: rotate(180deg);
    }
  }
}



.amount-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: #858585;
  font-size: 16px;
}

.amount-input {
  border-radius: 8px;
  border: 1px solid var(--stroke, #EBEBEB);
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: var(--white, #FFF);
  margin-top: 5px;
  /* Space between label and input */
}

.contribute-button {
  width: 100%;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  padding: 18px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 30px;
  background: #0DA885;
  color: white;
  /* Ensure text color contrasts with background */
  border: none;
  /* Remove default button border */
  cursor: pointer;
  /* Change cursor to pointer on hover */
}

.disabled-button {
  background-color: gray;
  /* Gray color when disabled */
  cursor: not-allowed;
  /* Shows a "not-allowed" cursor */
  opacity: 0.6;
  /* Optional: Makes it look more disabled */
}

.contribute-button:hover {
  background: #0a7e65;
  /* Optional: Add hover effect */
}

.amount-input {
  /* Existing styles */
  border-radius: 8px;
  border: 1px solid var(--stroke, #EBEBEB);
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: var(--white, #FFF);
}

/* Hide the spinner buttons in the input field */
.amount-input::-webkit-inner-spin-button,
.amount-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  /* For Chrome, Safari, and Opera */
  margin: 0;
  /* Remove margin */
}

.amount-input {
  -moz-appearance: textfield;
  /* For Firefox */
}



/* Animation styles for sliding effect */
.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.slide-enter,
.slide-leave-to

/* .slide-leave-active in <2.1.8 */
  {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  /* Prevents overflow while collapsing */
}

.toggle-button {
  color: var(--main-text, #1C1C1C);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .arrow-up {
    transform: rotate(180deg);
  }
}

.milestones-transaction-hash {
  color: var(--Primary-colour, #2EA8AF);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.circle-active {
  background-color: var(--Primary-colour, #2EA8AF);
  color: var(--white-text, #FFF);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}</style>
