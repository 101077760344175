<template>
  <div class="container">
    <div class="game-overview">
      <div class="game-overview-title">
        <p>{{ 'Contact details' }}</p>
      </div>
      <div class="game-overview-description">

        <!-- <div id="viewEditor">
          <div class="quillWrapper">
            <div class="quill-container">
              <div class="ql-editor ql-blank">
                <div v-html="contactDetails"></div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- Display the email contact separately -->
        <div v-if="emailContact">
          <span v-if="emailContact.description !== 'Not found'">{{ emailContact.description }}</span>
        </div>

        <!-- Display other contacts -->
        <!-- <ul>
          <li v-for="contact in filteredContactDetails" :key="contact.id">
            <span>{{ contact.name }}</span>:

            <a :href="contact.description" v-if="contact.description !== 'Not found'" _target="new ">{{
              contact.description }}</a>
          </li>
        </ul> -->
        <div class="connect-wrapper">
          <ConnectItem v-for="item in filteredContactDetails" :key="item.id" :item="item" class="connect-item" />
        </div>
      </div>
      <div v-if="videoUrl" class="video-wrapper">
        <video :src="videoUrl" class="video" controls></video>
      </div>

    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-quill-editor'
import ConnectItem from '@/components/common/connectItem.vue';


export default {
  name: 'GameContactDetailsViewSection',
  props: {
    contactDetails: {
      type: Object, // Assuming overview is a text
      default: []
    },
  },
  data() {
    return {
      // Your existing data properties
      gamePlayToolbar: [

      ],
      items: [
        { id: 1, imageUrl: "images/homePage/reddit.svg", text: "Reddit", link: 'https://www.reddit.com/r/atmrank/' },
        { id: 2, imageUrl: "images/homePage/twitter.svg", text: "Twitter", link: 'https://twitter.com/ATMrank' },
        { id: 3, imageUrl: "images/homePage/telegram.svg", text: "Telegram", link: 'https://t.me/atm_luca' },
        { id: 4, imageUrl: "images/homePage/discord.svg", text: "Discord", link: 'https://discord.gg/bwAtDM7Mp2' },
      ]
    };
  },
  components: {
    VueEditor,
    ConnectItem
    // Your existing components
  },
  computed: {
    filteredContactDetails() {
      let data = this.contactDetails
        .filter(contact => contact.name !== "email" && contact.description !== "Not found");
      console.log(data)

      const items = data.map((item, index) => ({
        id: index + 1,
        imageUrl: this.getImageUrl(item.name), // Use the function to get the image URL based on the name
        text: item.name,
        link: item.description // Assuming `description` contains the link
      }));
      return items;
    }
  },
  created() {
    this.emailContact = this.contactDetails.find(contact => contact.name === "email");
  },
  mounted() {
    // Your existing mounted lifecycle hook
  },
  beforeMount() {
    // Your existing beforeMount lifecycle hook
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress);
    window.removeEventListener('resize', this.arrangeCardList);
  },
  methods: {
    // Your existing methods
    getImageUrl(name) {
      console.log(name);
      const imageMap = {
        "Reddit": "images/homePage/reddit.svg",
        "Twitter": "images/homePage/twitter.svg",
        "Telegram": "images/homePage/telegram.svg",
        "Discord": "images/homePage/discord.svg",
        "Facebook": "images/homePage/facebook.svg",
        "Portfolio": "images/homePage/portfolio.svg",
        "Bilibili": "images/homePage/bilibili.png",
        "YouTube": "images/homePage/youtube.png",
        "Weibo": "images/homePage/weibo.png",
        "KOOK": "images/homePage/kook.png"
      };

      // Return the corresponding image URL or a default image if not found
      return imageMap[name] || "images/homePage/default.svg";
    }
  }
}
</script>
<style scoped>
/* Remove the border of the editor */

.container {
  width: 100%;
  padding: 20px 20px;
  @media (max-width: 550px) {
    padding: 10px 0px;
  }

}

.game-overview {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.game-overview-title p {
  color: var(--Primary-colour, #2EA8AF);
  font-family: "Bebas Neue";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.game-overview-description p {
  color: #000;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
}

.game-overview-subtitle {
  color: #000;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  padding-bottom: 10px;
}

.video-wrapper {
  width: 100%;
  height: 450px;
  overflow: hidden;
  border-radius: 15px;
  margin-top: 20px;
}

.video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
</style>

