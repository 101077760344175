<template>
    <div ref="chart" class="pie-chart"></div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import * as echarts from 'echarts';

export default {
    name: 'GamePieChart',
    props: {
        chartData: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const chart = ref(null);
        let myChart = null;

        const updateChart = () => {
            if (!Array.isArray(props.chartData)) {
                console.error("Expected arrays for chartData");
                return;
            }

            // Extract values for Total contribution and Goal
            const totalContribution = props.chartData.find(item => item.name === 'Total contribution').value;
            const goal = props.chartData.find(item => item.name === 'Goal').value;
            const percentage = ((totalContribution / goal) * 100).toFixed(0);

            const option = {
                color: ['#3E688C', '#D4E3EF'],
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => params.name
                },
                series: [
                    {
                        name: 'Outer Ring',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        data: [
                            { value: totalContribution, name: 'Total contribution' },
                            { value: goal - totalContribution, name: 'Remaining' }
                        ],
                        minAngle: 2, 
                        itemStyle: {
                            borderRadius: 10,  // Round the edges of each pie slice
                            borderColor: '#fff',  
                            borderWidth: 2,
                
                        },
                        label: {
                            show: false // No labels on the chart itself
                        }
                    }
                ],
                // Custom center text for percentage
                graphic: [
                {
                        type: 'text',
                        left: 'center',
                        top: 'center',
                        style: {
                            text: `${percentage}%\nof the funds`, // Text with new line
                            textAlign: 'center',
                            fill: '#3E688C',
                            fontSize: 20,
                            fontWeight: 'bold',
                            lineHeight: 30
                        }
                    }
                   
                ]
            };

            if (myChart) {
                myChart.setOption(option);
            }
        };

        const resizeChart = () => {
            if (myChart) {
                myChart.resize(); 
            }
        };

        onMounted(() => {
            if (chart.value) {
                myChart = echarts.init(chart.value);
                updateChart();
                window.addEventListener('resize', resizeChart); 
            }
        });

        onBeforeUnmount(() => {
            if (myChart) {
                window.removeEventListener('resize', resizeChart); 
            }
        });

        watch(() => [props.chartData], () => {
            updateChart();
        });

        return {
            chart
        };
    }
};
</script>

<style lang="scss" scoped>
.pie-chart {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
