/* eslint-disable no-unused-vars */
import axios from "axios";

// const API_BASE_URL = "http://localhost:8080"; // Replace with your base API URL

const API_BASE_URL = " http://atmgameapi.5544444455.com"; // Test env.

// const  API_BASE_URL = process.env.VUE_APP_LUCY_BASE_PATH;  // Productions
// const  API_BASE_URL = "https://gameapi.atm.network";

function getHeaders() {
  const loginToken = localStorage.getItem("token");
  return {
    token: loginToken,
    "Content-Type": "application/json",
  };
}

function getAuthHeaders() {
  const loginToken = localStorage.getItem("token");
  console.log(" this is the auth header ....", loginToken);
  let headers = {
    token:`${loginToken}`,
    'cssg-language': 'en',
    "Content-Type": "application/json"

  };
  // document.cookie = `token=${loginToken}; path=/; samesite=None; secure;`;

  return headers;
}

export async function createProposal(data, errorText = "Something went wrong") {
  try {
    // Define the API endpoint
    const apiUrl = API_BASE_URL + "/game/createProposal";


    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: getHeaders(),
      data: data,
    };

    let response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
     
        throw res;
      });
 
    // Handle the response
    let res = {
      message: response.data || "",
      isSuccess: response.success || false,
    };

    return res;
  } catch (error) {
    console.log(error);
    let res = {
      message: "Something went wrong",
      isSuccess: false,
    };
    return res;
    // throw res; // Optionally rethrow to handle higher up
  }
}

export async function updateProposal(data, errorText = "Something went wrong") {
  try {
    // Define the API endpoint
    const apiUrl = API_BASE_URL + "/game/updateProposal";

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: getHeaders(),
      data: data,
    };

    let response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw res;
      });
    
    // Handle the response
    let res = {
      message: response.data || "",
      isSuccess: response.success || false,
    };

    return res;
  } catch (error) {
    console.log(error);
    let res = {
      message: "Something went wrong",
      isSuccess: false,
    };
    return res;
  }
}

export async function getProposalByUserId(pageIndex) {
  try {
    const userData = JSON.parse(localStorage.getItem("userData")) || [];

    let userId = userData.user.id;
    const apiUrl = API_BASE_URL + "/game/getProposalByUserId";

    let data = JSON.stringify({
      userId: userId,
    });

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: getHeaders(),
      data: data,
    };

    let response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw res;
      });

    let res = {
      data: response.data || "",
      isSuccess: response.success || false,
    };
    console.log(res);

    return res;
  } catch (error) {
    console.log(error);
    let res = {
      message: "Something went wrong",
      isSuccess: false,
    };
    return res;
    // throw res; // Optionally rethrow to handle higher up
  }
}

export async function getAdminProposal(pageIndex) {
  try {
    const userData = JSON.parse(localStorage.getItem("userData")) || [];

    let userId = userData.user.id;
    const apiUrl = API_BASE_URL + "/game/getAllProposal";

    let data = JSON.stringify({
       "blank": "blank",
    });

    var config = {
      withCredentials: true,
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: getAuthHeaders(),
      data: data,
    };

    let response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw res;
      });
   
    let res = {
      data: response.data || "",
      isSuccess: response.success || false,
    };
    console.log(res);

    return res;
  } catch (error) {
    console.log(error);
    let res = {
      message: "Something went wrong",
      isSuccess: false,
    };
    return res;
    // throw res; // Optionally rethrow to handle higher up
  }
}

export async function updateProposalByAdmin(dataObject) {
  try {
    const userData = JSON.parse(localStorage.getItem("userData")) || [];

    let userId = userData.user.id;
    const apiUrl = API_BASE_URL + "/game/updateGameStatus";

    let data = JSON.stringify({
      ...dataObject,
    });

    var config = {
      withCredentials: true,
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: getAuthHeaders(),
      data: data,
    };

    let response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw res;
      });

    let res = {
      data: response.data || "",
      isSuccess: response.success || false,
    };

    return res;
  } catch (error) {
    console.log(error);
    let res = {
      message: "Something went wrong",
      isSuccess: false,
    };
    return res;
  }
}



export async function getGameById(gmaeId) {
  try {
  
    const apiUrl = API_BASE_URL + "/game/getGameById";

    let data = JSON.stringify({
      id: gmaeId,
    });

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: getHeaders(),
      data: data,
    };

    let response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw res;
      });

    let res = {
      data: response.data || "",
      isSuccess: response.success || false,
    };
    console.log(res);

    return res;
  } catch (error) {
    console.log(error);
    let res = {
      message: "Something went wrong",
      isSuccess: false,
    };
    return res;
    // throw res; // Optionally rethrow to handle higher up
  }
}


export async function gameRating(dataObject) {
  try {
    const loginToken = localStorage.getItem("token");

    const apiUrl = API_BASE_URL + "/game/anonymousRating";

    const apiAuthUrl = API_BASE_URL + "/game/knownRating";

    let data = JSON.stringify({
      ...dataObject,
    });

    let config = {
  
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: getHeaders(),
      data: data,
    };
    
    if(loginToken){
      config ={
        withCredentials : true,
        method: "post",
        url: apiAuthUrl,
        maxBodyLength: Infinity,
        headers:getAuthHeaders(),
        data:data
      }
    }

    let response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw res;
      });

    let res = {
      data: response.data || "",
      isSuccess: response.success || false,
    };

    return res;
  } catch (error) {
    console.log(error);
    let res = {
      message: "Something went wrong",
      isSuccess: false,
    };
    return res;
  }
}

export async function gameContributed(dataObject) {
  try {


    const apiAuthUrl = API_BASE_URL + "/game/invest";

    let data = JSON.stringify({
      ...dataObject,
    });

     let config ={
        withCredentials : true,
        method: "post",
        url: apiAuthUrl,
        maxBodyLength: Infinity,
        headers:getAuthHeaders(),
        data:data
      }
    s

    let response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw res;
      });

    let res = {
      data: response.data || "",
      isSuccess: response.success || false,
    };

    return res;
  } catch (error) {
    console.log(error);
    let res = {
      message: "Something went wrong",
      isSuccess: false,
    };
    return res;
  }
}